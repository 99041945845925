<template>
  <div class="mobile-account-form">
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="form.menu_account"
          :label="$trans('mobile_account_form_menu_bookings_label')"
          outlined
          hide-details
          maxlength="15"
          @input="updateTranslationInput"
          @blur="changeTranslationsBlur"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MobileAccountForm",
  data() {
    return {
      form: {
        menu_account: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      getTranslations: "mobileEditor/getTranslations",
    }),
  },
  watch: {
    getTranslations() {
      this.updateFields();
    },
  },
  beforeMount() {
    this.updateFields();
  },
  methods: {
    ...mapActions({
      changeConfiguration: "mobileEditor/changeConfiguration",
      updateTranslation: "mobileEditor/updateTranslation",
    }),
    updateFields() {
      if (!this.getTranslations) return;
      this.form.menu_account = this.getTranslations.menu_account;
    },
    updateTranslationInput() {
      this.updateTranslation({ translations: { ...this.form } });
    },
    changeTranslationsBlur() {
      this.changeConfiguration({
        translations: { ...this.form },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-account-form .row {
  margin-bottom: 2rem;
}
</style>
